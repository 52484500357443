<template>
    <div class="text-center">
        <navbar />
        <h1 class="text-h1">Bienvenido</h1>
    </div>
</template>

<script>
import navbar from '../components/navigation/navbar.vue'
export default {
    components: { navbar },
    name:'Home',
}
</script>

<style scoped>
</style>
